var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('v-card',{staticClass:"pa-2 mb-2",attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"ml-1",attrs:{"medium":"","color":"primary"},on:{"click":_vm.btnsearch}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Load Data")],1)],1)],1)],1),_c('v-card',{staticClass:"pa-1 mb-2",attrs:{"flat":""}},[_c('v-data-table',{staticStyle:{"border-radius":"0px"},attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"application_id","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',[_c('v-spacer'),_c('div',{staticStyle:{"width":"300px"}},[_c('v-text-field',{staticClass:"mt-2 mb-2 pa-1",attrs:{"dense":"","solo-inverted":"","flat":"","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticStyle:{"text-align":"left","width":"50px"}},[_vm._v(" "+_vm._s(item.app_id)+" ")]),_c('td',{staticStyle:{"text-align":"left","width":"65px"}},[_c('img',{staticStyle:{"border-radius":"10px","border":"1px solid #ccc"},attrs:{"src":_vm.$apiPath + item.photo,"height":"60px","width":"60px","type":"button"}})]),_c('td',[_vm._v(_vm._s(item.firstName))]),_c('td',[_vm._v(_vm._s(item.mediaName))]),_c('td',[_vm._v(_vm._s(item.role_name))]),_c('td',[_vm._v(_vm._s(item.print_text))]),_c('td',[_vm._v(_vm._s(item.status_named))]),_c('td',[_vm._v(_vm._s(item.mediaType_name))]),_c('td',[_vm._v(_vm._s(item.reason_name))]),_c('td',[(item.status_name == 'Cancel')?_c('v-btn',{attrs:{"medium":"","color":"success"},on:{"click":function($event){return _vm.cancelcard(
                  item.application_id,
                  item.status_name,
                  item.status_id
                )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-reactivate")]),_vm._v("ACTIVE")],1):_vm._e(),(item.status_name != 'Cancel')?_c('v-btn',{attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.cancelcard(
                  item.application_id,
                  item.status_named,
                  item.status_id
                )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v("CANCEL")],1):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_vm._v(" No Record(s) ")]},proxy:true}]),model:{value:(_vm.TDselected),callback:function ($$v) {_vm.TDselected=$$v},expression:"TDselected"}})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialogcancel),callback:function ($$v) {_vm.dialogcancel=$$v},expression:"dialogcancel"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Application ID - "+_vm._s(_vm.APPID_FOR_CANCEL))]),_c('v-card-text',[_c('div',{staticClass:"text-h5 pa-5"},[_vm._v(_vm._s(_vm.statusmsg))]),_c('v-select',{attrs:{"label":"Select Reason","items":_vm.ResonForUpdate,"item-text":"reason_name","item-value":"reason_id","outlined":"","hide-details":"","dense":""},model:{value:(_vm.SelReasonForUpdate),callback:function ($$v) {_vm.SelReasonForUpdate=$$v},expression:"SelReasonForUpdate"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.btncancel}},[_vm._v("NO")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.btnsave}},[_vm._v("YES")])],1)],1)],1),_c('v-snackbar',{attrs:{"shaped":"","top":"","color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbartext)+" "),_c('v-btn',{attrs:{"color":"indigo","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }